<template>
  <div>123</div>
</template>

<script>
/* eslint-disable */
// require('../LoginCode/wwLogin')
// import './components/LoginCode/wwLogin'
/* eslint-enable */
// xeTmKepwqwCqhobebRjNYewKfS0kVtxTwemX85Qz_mk
export default {
  // data () { return{} },
  async created () {
    try {
      const { data: res } = await this.$http.get(document.location.protocol + `//api-dataview.bjstarfish.com/api/v1/login_with_code?code=${this.$route.query.code}`)
      if (res.msg !== 'success') return this.$message({ message: '登录失败', type: 'error' })
      this.$message({ message: '登录成功', type: 'success' })
      window.sessionStorage.setItem('token', res.data.token)
      this.$router.push('/home')
    } catch (err) {
      this.$message({ message: '登录失败', type: 'error' })
    }
  }
}
</script>
